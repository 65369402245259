// UpcomingEvent.js
import React from 'react';
import { Link } from 'react-router-dom';
import './UpcomingEvent.css';

const UpcomingEvent = ({ event, onNext, onPrev }) => {
  // Determine the registration URL based on the event title.
  const registrationLink = event.title === 'Aubade 2025 (Concert @ Esplanade Recital Studio)'
    ? 'https://www.eventbrite.sg/e/aubade-by-restring-guitar-ensemble-tickets-1135788446819'
    : '/join-us';

  return (
    <div className="upcoming-event">
      <div className="event-image-container">
        <img src={event.image} alt={event.title} className="event-image" />
      </div>
      <div className="event-details">
        <h2>{event.title}</h2>
        <h3>{event.subtitle}</h3>
        <p className="event-description">{event.description}</p>
        {/* If the registration link is external, use an anchor tag */}
        {registrationLink.startsWith('http') ? (
          <a 
            href={registrationLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="event-button"
          >
            Buy Tickets
          </a>
        ) : (
          <Link to={registrationLink} className="event-button">
            Join Us
          </Link>
        )}
        <div className="event-navigation">
          <button onClick={onPrev} className="nav-button">{'<'}</button>
          <button onClick={onNext} className="nav-button">{'>'}</button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvent;